@import url(https://fonts.googleapis.com/css?family=Righteous);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Global Styles */
body, * {
  margin: 0;
  padding: 0; }

section {
  height: 100vh; }

li {
  list-style-type: none;
  padding: .5vh 0; }

a {
  text-decoration: none;
  color: #fff; }

a:hover {
  color: #00c9db !important; }

.btn {
  text-align: center;
  padding: 10px 30px;
  margin: 0 .75vw;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 1.75vw;
  color: #fff;
  background: linear-gradient(to right, #00c9db 0%, #0099d6 100%);
  box-shadow: none; }

.btn:hover {
  cursor: pointer; }

.logo {
  background-image: url(/static/media/master_logo.05281676.png);
  background-repeat: no-repeat;
  position: relative;
  top: 10vh;
  left: 0vw;
  height: 10vh;
  width: 25vw;
  margin-bottom: -10vh; }

h1, h2 {
  letter-spacing: 3px;
  font-family: 'Righteous', cursive;
  text-transform: uppercase; }

@-webkit-keyframes color-change-2x {
  0% {
    background: #19dcea; }
  100% {
    background: #b22cff; } }

@keyframes color-change-2x {
  0% {
    background: #19dcea; }
  100% {
    background: #b22cff; } }

/* Nav Styles */
.hamburger {
  position: absolute;
  right: 5vw;
  top: 5vh;
  color: #fff;
  z-index: 2;
  font-size: 3vw; }
  .hamburger:hover {
    font-size: 3.5vw;
    cursor: pointer; }

.navMenu {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 15%;
  width: 65%;
  border-radius: 15px;
  opacity: .75;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  z-index: 2; }
  .navMenu .nav-link {
    font-size: 2.5vw;
    font-family: 'Righteous', cursive;
    color: #00c9db;
    padding: 0 1.5vh;
    z-index: 1; }
    .navMenu .nav-link:hover {
      color: #000 !important;
      font-size: 3vw;
      transition: .5s; }

.returnBtn {
  position: relative;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  left: .5vw;
  font-size: 2.5vw;
  color: #00c9db; }
  .returnBtn:hover {
    color: #000;
    cursor: pointer; }

/* Carousel Section Styles */
.slides > section {
  position: relative;
  width: 100vh; }

.slide, .slide1, .slide2, .slide3 {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  transition-duration: 550ms;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1); }
  .slide h1, .slide1 h1, .slide2 h1, .slide3 h1 {
    position: relative;
    top: 35vh;
    left: 10vw;
    color: #fff;
    font-size: 4vw;
    z-index: 2; }
  .slide p, .slide1 p, .slide2 p, .slide3 p {
    position: relative;
    top: 35vh;
    left: 10vw;
    color: #fff;
    font-size: 1.5vw;
    z-index: 2;
    display: inline; }
  .slide button, .slide1 button, .slide2 button, .slide3 button {
    display: none; }

.slide1 {
  background-image: url(/static/media/slide1.d0771043.png);
  background-size: cover;
  background-position-x: 100%; }

.slide2 {
  background-image: url(/static/media/slide2.60929f5c.png);
  background-size: cover; }

.slide3 {
  background-image: url(/static/media/slide3.e41d607c.png);
  background-size: cover; }

.logo {
  position: absolute;
  top: 5vh;
  left: 2.5vw;
  z-index: 2;
  background-repeat: no-repeat; }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #0b0100;
  background: linear-gradient(90deg, black 22%, rgba(255, 255, 255, 0) 100%);
  opacity: .7; }

/* Game Section Styles */
.games {
  background: slategrey; }
  .games .list-row {
    height: 50%; }
  .games .list-row-top {
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%); }
  .games .list-row-bottom {
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%); }

.search-link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%); }
  .search-link h2 {
    position: relative;
    top: 5vh;
    text-align: center;
    color: #fff;
    font-size: 4vw; }
    .search-link h2:after {
      content: "";
      display: block;
      margin: 2vh auto;
      height: .5vh;
      width: 15vw;
      background: #00c9db; }
  .search-link .searchBtn {
    position: relative;
    top: 10vh; }

.game-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .game-list .game {
    height: 50vh;
    width: 25vw; }

.card1 {
  background-image: url(/static/media/apex-legends.2a625eb7.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card2 {
  background-image: url(/static/media/battlefieldV.6322228b.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card3 {
  background-image: url(/static/media/rainbowsix.7f04d102.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card4 {
  background-image: url(/static/media/fortnite.0cc18784.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card5 {
  background-image: url(/static/media/overwatch.254e8b84.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card6 {
  background-image: url(/static/media/league.0c982ee5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.card7 {
  background-image: url(/static/media/cs-go.f92ceb84.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

/* Game Search Section */
.open {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  transition: 1.5s;
  visibility: visible; }

.close {
  height: 50vh;
  width: 25vw;
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  transition: 1.8s; }

.gameList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%; }

.list-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: scroll;
  width: 100%;
  max-height: 72vh; }
  .list-wrapper #game-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center; }
    .list-wrapper #game-list li {
      font-size: 2.5vw;
      color: #fff; }

.searchBar {
  margin-top: 5.5vh;
  font-size: 2.5vw;
  border-radius: 8px;
  padding: .5vh;
  width: 30%; }

.return {
  position: absolute;
  top: 2.5vh;
  left: 2.5vw;
  font-size: 5vw;
  color: #fff; }
  .return:hover {
    color: #00c9db; }

/* End Game Search Section */
/* CSSTransition styles Section */
/* Rates Section Styles */
.rates {
  height: 150vh;
  text-align: center;
  background-image: url(/static/media/background.e1482e7c.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 20%; }
  .rates .title {
    position: relative;
    top: 8vh;
    font-size: 4.25vw;
    color: #fff;
    text-align: center; }
    .rates .title:after {
      content: "";
      display: block;
      margin: 2vh auto;
      height: .5vh;
      width: 20vw;
      background: #00c9db; }
  .rates .location-rate, .rates .prices {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .rates .location-rate {
    top: 12vh; }
  .rates .prices {
    top: 15vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }

.current {
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both; }

.active {
  visibility: visible;
  transition: 1s; }

.inActive {
  display: none;
  transition: 1s; }

.reveal {
  visibility: visible; }

.hide {
  display: none !important; }

/* PriceInfo Section Styles */
.price-info {
  position: relative;
  height: 90vh;
  top: 30vh; }
  .price-info .price-wrapper {
    position: relative;
    top: -8vh;
    width: 95%;
    padding: 0 2.5%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
  .price-info .card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
    height: auto;
    max-height: 75%;
    background: #f2f5fa;
    opacity: .95;
    border-radius: 8px;
    padding: 6% 5%;
    margin: 2.5vw; }
    .price-info .card h3 {
      color: black;
      font-size: 4vw;
      margin-bottom: 2.5vh;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
    .price-info .card p {
      margin: 2vh 0;
      font-size: 1.5vw; }
    .price-info .card hr {
      color: lightgrey;
      width: 55%; }
    .price-info .card .cost {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      margin-bottom: 2.5vh;
      opacity: .5;
      font-size: 4vw; }
  .price-info .descriptor {
    -webkit-justify-content: center;
            justify-content: center;
    margin: -2.5vw;
    border: 4px solid #00c9db;
    background: #fff;
    max-height: 70%; }
    .price-info .descriptor p {
      font-size: 2.5vw; }

/* End PriceInfo Styles */
/* Contact Section Styles */
.contact {
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }
  .contact .contact-info, .contact .reservations {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .contact .contact-info {
    width: 55%;
    padding: 0 2.5vw;
    position: relative;
    top: 10vh;
    margin-bottom: 10vh;
    color: #fff; }
    .contact .contact-info h2 {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      font-size: 3.5vw;
      padding-bottom: 5vh; }
    .contact .contact-info p {
      text-align: justify;
      font-size: 2vw; }
    .contact .contact-info .features {
      display: -webkit-flex;
      display: flex;
      padding-top: 2.5vh; }
      .contact .contact-info .features i {
        position: relative;
        top: 1.5vh;
        left: 1.5vw;
        font-size: 3vw; }
      .contact .contact-info .features p {
        padding: 0 2.75vw; }
    .contact .contact-info .numbers {
      position: relative;
      top: 5vh;
      text-align: center; }
      .contact .contact-info .numbers .number {
        color: #00c9db; }
      .contact .contact-info .numbers h3, .contact .contact-info .numbers li {
        font-size: 2vw;
        padding: .5vh 0; }
  .contact .form-wrapper {
    width: 35%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .contact .form-wrapper form {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      position: relative;
      top: -6vh;
      padding-bottom: 2.5vh; }
      .contact .form-wrapper form .input-field {
        text-align: center;
        position: relative;
        top: -2vh; }
    .contact .form-wrapper h2 {
      position: relative;
      width: 100%;
      text-align: center;
      top: -4vh;
      background: linear-gradient(to right, #00c9db 0%, #0099d6 100%);
      padding: 2.5vh 0;
      border-top-left-radius: 2.5vw;
      border-top-right-radius: 2.5vw;
      font-size: 2.5vw; }
    .contact .form-wrapper input, .contact .form-wrapper textarea {
      border: 1px solid #ababab;
      border-radius: .5vw;
      padding: 1.5vh .5vw;
      margin: 2vh 0;
      width: 75%; }
      .contact .form-wrapper input::-webkit-input-placeholder, .contact .form-wrapper textarea::-webkit-input-placeholder {
        color: grey;
        font-size: 2vw;
        margin-left: 1.5vw; }
      .contact .form-wrapper input::-ms-input-placeholder, .contact .form-wrapper textarea::-ms-input-placeholder {
        color: grey;
        font-size: 2vw;
        margin-left: 1.5vw; }
      .contact .form-wrapper input::placeholder, .contact .form-wrapper textarea::placeholder {
        color: grey;
        font-size: 2vw;
        margin-left: 1.5vw; }
    .contact .form-wrapper .submitBtn {
      color: #fff; }

/* Form specific styles */
#contact-form {
  border: none;
  border-bottom-right-radius: 2.5vw;
  border-bottom-left-radius: 2.5vw;
  background: #fff;
  width: 75%; }

#submit {
  width: 65%;
  margin: 1.5vh 0;
  padding: 2vh; }

/* End form specific styles */
/* Location Section Styles */
.location-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .location-wrapper .location-map {
    height: 100%; }
  .location-wrapper .location-info {
    width: 35%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background-image: url(/static/media/location.fc251160.jpg);
    background-size: cover;
    color: #fff; }
    .location-wrapper .location-info .locations {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      position: relative;
      top: 10vh; }
      .location-wrapper .location-info .locations .tempeBtn, .location-wrapper .location-info .locations .phoenixBtn {
        margin: 1.5vh 0;
        font-size: 2vw; }
    .location-wrapper .location-info .address {
      position: relative;
      top: 12vh;
      text-align: center;
      height: 40vh; }
      .location-wrapper .location-info .address .hours, .location-wrapper .location-info .address li {
        font-size: 2vw;
        padding: 1.5vh 0; }
    .location-wrapper .location-info .socialBar {
      position: relative;
      top: 28vh; }
      .location-wrapper .location-info .socialBar a {
        padding: 0 1.5vw;
        font-size: 2vw;
        color: #fff; }
  .location-wrapper .location-map {
    background: indigo;
    width: 65%; }
    .location-wrapper .location-map .map {
      height: 100%;
      width: 100%; }
      .location-wrapper .location-map .map iframe {
        height: 100%;
        width: 100%; }

/* Footer Section */
footer {
  height: 5vh;
  background: #000;
  position: relative;
  top: 0vh;
  text-align: center; }
  footer span {
    position: relative;
    top: 1vh;
    color: #fff; }

/* Media-queries Section */
@media (max-width: 991.98px) {
  .rates {
    height: 135vh; }
    .rates .price-info {
      top: 32vh;
      height: auto; }
  .contact .contact-info {
    width: 45%; }
    .contact .contact-info h2 {
      width: 100%; }
  .contact .form-wrapper {
    width: 50%; }
    .contact .form-wrapper form {
      top: -5vh; } }

@media (max-width: 767.98px) {
  .hamburger {
    font-size: 4vw; }
  .slides h1 {
    font-size: 4vw; }
  .slides p {
    font-size: 2vw; }
  .slides button {
    left: -42vw; }
  .games {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: auto;
    background: slategrey; }
    .games .list-row {
      height: 100%;
      width: 50%; }
    .games .list-row-top {
      background: indigo; }
    .games .list-row-bottom {
      background: tomato; }
  .game-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .game-list .game {
      height: 50vh;
      width: auto; }
      .game-list .game .closed-title {
        font-size: 4.5vw;
        top: 8vh; }
      .game-list .game button {
        font-size: 3vw;
        top: 12vh; }
  .rates {
    height: 110vh; }
    .rates .title {
      font-size: 4.5vw; }
    .rates .btn {
      font-size: 2.5vw; }
    .rates .price-info {
      top: 30vh; }
  .contact {
    height: 110vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
    .contact .contact-info {
      top: -2vh;
      width: auto; }
    .contact .form-wrapper {
      width: auto; }
      .contact .form-wrapper form {
        top: -5vh; }
      .contact .form-wrapper #contact-form {
        height: 40vh;
        top: 2vh; }
        .contact .form-wrapper #contact-form .input-field {
          text-align: center;
          position: relative;
          top: -2vh;
          height: 50%;
          width: 50%;
          left: -15vw;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: center;
                  justify-content: center; }
          .contact .form-wrapper #contact-form .input-field #comments {
            top: -28vh; }
        .contact .form-wrapper #contact-form #submit {
          position: relative;
          top: 4vh; }
      .contact .form-wrapper textarea {
        position: relative;
        left: 32vw;
        top: -27.25vh;
        padding-bottom: 4vh; } }

.location-info .btn {
  font-size: 3vw; }

.location-info .address {
  position: absolute;
  margin-top: 5vh; }

.location-info .socialBar {
  margin-top: -2vh; }
  .location-info .socialBar a {
    font-size: 3vw; }

@media (max-width: 575.98px) {
  .slides .logo {
    height: 12vh;
    width: 45vw; }
  .slides .hamburger {
    font-size: 2.5rem;
    right: 8vw; }
  .slides .slide > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .slides .slide > div h1 {
      font-size: 2rem;
      width: 90%; }
    .slides .slide > div p {
      font-size: 1.05rem;
      width: 90%; }
    .slides .slide > div .btn {
      font-size: 3vw;
      width: 35%;
      top: 38vh;
      left: 8vw; }
  .navMenu {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 30%;
    height: 75%; }
    .navMenu .nav-link {
      font-size: 4vw; }
      .navMenu .nav-link:hover {
        font-size: 4vw; }
    .navMenu .returnBtn {
      font-size: 4vw; }
  .games {
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    top: -15vh;
    margin-bottom: -10vh; }
    .games .list-row {
      height: auto;
      width: 100%; }
  .game-list .game .return {
    top: 3vh;
    font-size: 1.75rem; }
  .game-list .game h2.closed-title {
    top: 8vh;
    font-size: 3rem; }
  .game-list .game .btn {
    font-size: 3.5vw; }
  .game-list .game h2.open-title {
    top: 2.5vh;
    font-size: 2.5rem; }
  .game-list .game input.searchBar {
    position: relative;
    top: -2vh;
    font-size: 2rem;
    width: auto; }
  .game-list .list-wrapper #game-list li {
    font-size: 1.25rem; }
  .rates {
    margin-top: -15vh;
    height: 175vh; }
    .rates .title {
      font-size: 2rem; }
    .rates .location-rate .btn {
      font-size: 1.05rem; }
    .rates .prices {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .rates .prices .btn {
        margin: 1vh;
        font-size: 1.05rem; }
      .rates .prices button.btn.twenty-four {
        position: relative;
        left: 2vw; }
    .rates .price-info .price-wrapper {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .rates .price-info .price-wrapper .card {
        width: 55%;
        padding: 4%;
        -webkit-order: 2;
                order: 2; }
        .rates .price-info .price-wrapper .card p {
          font-size: 1.5rem; }
      .rates .price-info .price-wrapper .descriptor {
        -webkit-order: 1;
                order: 1;
        margin-bottom: 4%; }
  .contact {
    height: 150vh; }
    .contact .contact-info {
      top: -8vh;
      max-width: 100%; }
      .contact .contact-info h2 {
        font-size: 1.5rem; }
      .contact .contact-info p {
        padding: 0 1vh;
        font-size: 1.05rem; }
      .contact .contact-info .numbers h3, .contact .contact-info .numbers li {
        font-size: 1.05rem; }
  .contact .form-wrapper #contact-form {
    top: 0vh;
    height: 35vh; }
    .contact .form-wrapper #contact-form .input-field input {
      padding: 1vh;
      margin: 2vh 5vw;
      width: 70%; }
    .contact .form-wrapper #contact-form .input-field #comments {
      top: -25vh;
      height: 15.15vh; }
    .contact .form-wrapper #contact-form #submit {
      font-size: 1.05rem;
      top: 5vh;
      width: 50%;
      padding: 1vh; }
  .location-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column; }
    .location-wrapper .location-info {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      height: 35%;
      width: 100%; }
      .location-wrapper .location-info .locations {
        height: auto;
        width: 50%;
        position: relative;
        top: -6vh;
        -webkit-align-items: flex-end;
                align-items: flex-end; }
        .location-wrapper .location-info .locations .btn {
          width: 80%;
          font-size: 1.05rem; }
      .location-wrapper .location-info .address {
        position: relative;
        height: auto;
        width: 50%;
        top: -4vh; }
        .location-wrapper .location-info .address .hours, .location-wrapper .location-info .address li {
          font-size: 1rem; }
      .location-wrapper .location-info .socialBar {
        position: relative;
        top: -6vh;
        left: -18vw; }
        .location-wrapper .location-info .socialBar ul a {
          font-size: 1.5rem;
          margin: 1vh; }
    .location-wrapper .location-map {
      height: 65%;
      width: 100%; } }

