/* Global Styles */
body, * {
  margin: 0;
  padding: 0;
}
section {
  height: 100vh;
  // overflow: hidden;
}
li {
  list-style-type: none;
  padding: .5vh 0;
}
a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: #00c9db !important;
}
.btn {
  text-align: center;
  padding: 10px 30px;
  margin: 0 .75vw;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 1.75vw;
  color: #fff;
  background: linear-gradient(to right, #00c9db 0%, #0099d6 100%);
  box-shadow: none;
}
.btn:hover {
  cursor: pointer;
}
.logo {
  background-image: url('./images/master_logo.png');
  background-repeat: no-repeat;
  position: relative;
  top: 10vh;
  left: 0vw;
  height: 10vh;
  width: 25vw;
  margin-bottom: -10vh;
}
h1, h2 {
  letter-spacing: 3px;
  font-family: 'Righteous', cursive;
  text-transform: uppercase;
}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Righteous');
// BUTTON ON-FOCUS ANIMATION
@-webkit-keyframes color-change-2x{0%{background:#19dcea}100%{background:#b22cff}}@keyframes color-change-2x{0%{background:#19dcea}100%{background:#b22cff}};

/* Nav Styles */

.hamburger {
  position: absolute;
  right: 5vw;
  top: 5vh;
  color: #fff;
  z-index: 2;
  font-size: 3vw;
  &:hover {
    font-size: 3.5vw;
    cursor: pointer;
  }
}
.navMenu {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 15%;
  width: 65%;
  border-radius: 15px;
  opacity: .75;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  .nav-link {
    font-size: 2.5vw;
    font-family: 'Righteous', cursive;
    color: #00c9db;
    padding: 0 1.5vh;
    z-index: 1;
    &:hover {
      color: #000 !important;
      font-size: 3vw;
      transition: .5s;
    }
  }
}
.returnBtn {
  position: relative;
  transform: rotate(180deg);
  left: .5vw;
  font-size: 2.5vw;
  color: #00c9db;
  &:hover {
    color: #000;
    cursor: pointer;
  }
}

/* Carousel Section Styles */
.slides {
  // overflow: hidden;
}
.slides > section {
  position: relative;
  width: 100vh;
}
.slide, .slide1, .slide2, .slide3 {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  transition-duration: 550ms ;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  h1 {
    position: relative;
    top: 35vh;
    left: 10vw;
    color: #fff;
    font-size: 4vw;
    z-index: 2;
  }
  p {
    position: relative;
    top: 35vh;
    left: 10vw;
    color: #fff;
    font-size: 1.5vw;
    z-index: 2;
    display: inline;
  }
  button {
    display: none;
    // position: relative;
    // top: 42vh;
    // left: -30vw;
    // z-index: 2;
  }
}
.slide1 {
  background-image: url('./images/slide1.png');
  background-size: cover;
  // background-repeat: no-repeat;
  background-position-x: 100%;
}
.slide2 {
  background-image: url('./images/slide2.png');
  background-size: cover;
  // background-repeat: no-repeat;
}
.slide3 {
  background-image: url('./images/slide3.png');
  background-size: cover;
  // background-repeat: no-repeat;
}
.logo {
  position: absolute;
  top: 5vh;
  left: 2.5vw;
  z-index: 2;
  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(11,1,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 22%, rgba(255,255,255,0) 100%);
  opacity: .7;
}

/* Game Section Styles */

.games {
  background: slategrey;
  .list-row {
    height: 50%;
  }
  .list-row-top {
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  }
  .list-row-bottom {
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  }
}
.search-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  h2 {
    position: relative;
    top: 5vh;
    text-align: center;
    color: #fff;
    font-size: 4vw;
    &:after {
      content: "";
      display: block;
      margin: 2vh auto;
      height: .5vh;
      width: 15vw;
      background: #00c9db;
    }
  }
  .searchBtn {
    position: relative;
    top: 10vh;
  }
}

.game-list {
  display: flex;
  flex-direction: row;
  .game {
    height: 50vh;
    width: 25vw;
  }
}

.card1 {
  background-image: url('./images/apex-legends.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card2 {
  background-image: url('./images/battlefieldV.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card3 {
  background-image: url('./images/rainbowsix.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card4 {
  background-image: url('./images/fortnite.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card5 {
  background-image: url('./images/overwatch.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card6 {
  background-image: url('./images/league.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card7 {
  background-image: url('./images/cs-go.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

  /* Game Search Section */

  .open {
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
    transition: 1.5s;
    visibility: visible;
  }

  .close {
    height: 50vh;
    width: 25vw;
    background-color: #47667b;
    background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
    transition: 1.8s;
  }
  .gameList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
    .list-wrapper {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      width: 100%;
      max-height: 72vh;
      #game-list {
        display: flex;
        flex-direction: column;
        text-align: center;
        li {
          font-size: 2.5vw;
          color: #fff;
        }
      }
    }
    .searchBar {
      margin-top: 5.5vh;
      font-size: 2.5vw;
      border-radius: 8px;
      padding: .5vh;
      width: 30%;
    }
  .return {
    position: absolute;
    top: 2.5vh;
    left: 2.5vw;
    font-size: 5vw;
    color: #fff;
    &:hover {
      color: #00c9db;
    }
  }

  /* End Game Search Section */

  /* CSSTransition styles Section */


/* Rates Section Styles */

.rates {
  height: 150vh;
  text-align: center;
  background-image: url('./images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 20%;
  .title {
    position: relative;
    top: 8vh;
    font-size: 4.25vw;
    color: #fff;
    text-align: center;
    &:after {
      content: "";
      display: block;
      margin: 2vh auto;
      height: .5vh;
      width: 20vw;
      background: #00c9db;
    }
  }
  .location-rate, .prices {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .location-rate {
    top: 12vh;
  }
  .prices {
    top: 15vh;
    display: flex;
    flex-direction: row;
  }
}
.current {
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
	animation: color-change-2x 2s linear infinite alternate both;
}
.active {
  visibility: visible;
  transition: 1s;
}
.inActive {
  display: none;
  transition: 1s;
}

.reveal {
  visibility: visible;
}
.hide {
  display: none !important;
}

  /* PriceInfo Section Styles */

  .price-info {
    position: relative;
    height: 90vh;
    top: 30vh;
    .price-wrapper {
      // height: 100%;
      position: relative;
      top: -8vh;
      width: 95%;
      padding: 0 2.5%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .card {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      align-items: center;
      align-self: center;
      height: auto;
      max-height: 75%;
      background: #f2f5fa;
      opacity: .95;
      border-radius: 8px;
      // border: 1px solid black;
      padding: 6% 5%;
      margin: 2.5vw;
      h3 {
        color: black;
        font-size: 4vw;
        margin-bottom: 2.5vh;
        width: max-content;
      }
      p {
        margin: 2vh 0;
        font-size: 1.5vw;
      }
      hr {
        color: lightgrey;
        width: 55%;
      }
      .cost {
        width: max-content;
        margin-bottom: 2.5vh;
        opacity: .5;
        font-size: 4vw
      }
    }
    .descriptor {
      justify-content: center;
      margin: -2.5vw;
      border: 4px solid #00c9db;
      background: #fff;
      max-height: 70%;
      // padding: 5vh 0;
        p {
        font-size: 2.5vw;
      }
    }
  }

  /* End PriceInfo Styles */

/* Contact Section Styles */

.contact {
  background-color: #47667b;
  background-image: linear-gradient(135deg, #47667b 0%, #1e283c 65%, #000000 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  .contact-info, .reservations {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-info {
    width: 55%;
    padding: 0 2.5vw;
    position: relative;
    top: 10vh;
    margin-bottom: 10vh;
    color: #fff;
    h2 {
      width: max-content;
      font-size: 3.5vw;
      padding-bottom: 5vh;
    }
    p {
      text-align: justify;
      font-size: 2vw;
    }
    .features {
      display: flex;
      padding-top: 2.5vh;
      i {
        position: relative;
        top: 1.5vh;
        left: 1.5vw;
        font-size: 3vw;
      }
      p {
        padding: 0 2.75vw;
      }
    }
    .numbers {
      position: relative;
      top: 5vh;
      text-align: center;
      .number {
        color: #00c9db;
      }
      h3, li {
        font-size: 2vw;
        padding: .5vh 0;
      }
    }
  }
  .form-wrapper {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: -6vh;
      padding-bottom: 2.5vh;
      .input-field {
        text-align: center;
        position: relative;
        top: -2vh;
      }
    }
    h2 {
      position: relative;
      width: 100%;
      text-align: center;
      top: -4vh;
      background: linear-gradient(to right, #00c9db 0%, #0099d6 100%);
      padding: 2.5vh 0;
      border-top-left-radius: 2.5vw;
      border-top-right-radius: 2.5vw;
      font-size: 2.5vw;
    }
    input, textarea {
      border: 1px solid #ababab;
      border-radius: .5vw;
      padding: 1.5vh .5vw;;
      margin: 2vh 0;
      width: 75%;
      &::placeholder {
        color: grey;
        font-size: 2vw;
        margin-left: 1.5vw;
      }
    }
    .submitBtn {
      color: #fff;
    }
  }
}

/* Form specific styles */

#contact-form {
  border: none;
  border-bottom-right-radius: 2.5vw;
  border-bottom-left-radius: 2.5vw;
  background: #fff;
  width: 75%;
}
#submit {
  width: 65%;
  margin: 1.5vh 0;
  padding: 2vh;
}

/* End form specific styles */

/* Location Section Styles */

.location-wrapper {
  // background: firebrick;
  height: 100%;
  display: flex;
  flex-direction: row;
  .location-map {
    height: 100%;
  }
  .location-info {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('./images/location.jpg');
    background-size: cover;
    color: #fff;
    .locations {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 10vh;
      .tempeBtn, .phoenixBtn {
        margin: 1.5vh 0;
        font-size: 2vw;
      }
    }
    .address {
      position: relative;
      top: 12vh;
      text-align: center;
      height: 40vh;
      .hours, li {
        font-size: 2vw;
        padding: 1.5vh 0;
      }
    }
    .socialBar {
      position: relative;
      top: 28vh;
      a {
        padding: 0 1.5vw;
        font-size: 2vw;
        color: #fff;
      }
    }
  }
  .location-map {
    background: indigo;
    width: 65%;
    .map {
      height: 100%;
      width: 100%;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}

/* Footer Section */

footer {
  height: 5vh;
  background: #000;
  position: relative;
  top: 0vh;
  text-align: center;
  span {
    position: relative;
    top: 1vh;
    color: #fff;
  }
}

/* Media-queries Section */

@media (max-width: 991.98px) {
  .rates {
    height: 135vh;
    .price-info {
      top: 32vh;
      height: auto;
    }
  }

  .contact {
    .contact-info {
      width: 45%;
      h2 {
        width: 100%;
      }
    }
    .form-wrapper {
      width: 50%;
      form {
        top: -5vh;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .hamburger {
    font-size: 4vw;
  }
  .slides {
    h1 {
      font-size: 4vw;
    }
    p {
      font-size: 2vw;
    }
    button {
      left: -42vw;
    }
  }
  .games {
    display: flex;
    flex-direction: row;
    height: auto;
    background: slategrey;
    .list-row {
      height: 100%;
      width: 50%;
    }
    .list-row-top {
      background: indigo;
    }
    .list-row-bottom {
      background: tomato;
    }
  }

  .game-list {
    display: flex;
    flex-direction: column;
    .game {
      height: 50vh;
      width: auto;
      .closed-title {
        font-size: 4.5vw;
        top: 8vh;
      }
      button {
        font-size: 3vw;
        top: 12vh;
      }
    }
  }

  .rates {
    height: 110vh;
    .title {
      font-size: 4.5vw;
    }
    .btn {
      font-size: 2.5vw;
    }
    .price-info {
      top: 30vh;
    }
  }

  .contact {
    height: 110vh;
    flex-direction: column;
    justify-content: center;
    .contact-info {
      top: -2vh;
      width: auto;
    }
    .form-wrapper {
      width: auto;
      form {
        top: -5vh;
      }
      #contact-form {
        height: 40vh;
        top: 2vh;
        .input-field {
          text-align: center;
          position: relative;
          top: -2vh;
          height: 50%;
          width: 50%;
          left: -15vw;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          #comments {
            top: -28vh;
          }
        }
        #submit {
          position: relative;
          top: 4vh;
        }
      }
      textarea {
        position: relative;
        left: 32vw;
        top: -27.25vh;
        padding-bottom: 4vh;
      }
    }
  }
}


.location-info {
  .btn {
    font-size: 3vw;
  }
  .address {
    position: absolute;
    margin-top: 5vh;
  }
  .socialBar {
    margin-top: -2vh;
    a {
      font-size: 3vw;
    }
  }
}


@media (max-width: 575.98px) {
.slides {
  .logo {
    height: 12vh;
    width: 45vw;
  }
  .hamburger {
    font-size: 2.5rem;
    right: 8vw;
  }
  .slide > div {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 2rem;
      width: 90%;
    }
    p {
      font-size: 1.05rem;
      width: 90%;
    }
    .btn {
      font-size: 3vw;
      width: 35%;
      top: 38vh;
      left: 8vw;
    }
  }
}

.navMenu {
  flex-direction: column;
  width: 30%;
  height: 75%;
  .nav-link {
    font-size: 4vw;
    &:hover {
      font-size: 4vw;
    }
  }
  .returnBtn {
    font-size: 4vw;
  }
}

.games {
  flex-direction: column;
  position: relative;
  top: -15vh;
  margin-bottom: -10vh;
  .list-row {
    height: auto;
    width: 100%;
  }
}
.game-list {
  .game {
    .return {
      top: 3vh;
      font-size: 1.75rem;
    }
    h2.closed-title {
      top: 8vh;
      font-size: 3rem;
    }
    .btn {
      font-size: 3.5vw;
    }
    h2.open-title {
      top: 2.5vh;
      font-size: 2.5rem;
    }
    input.searchBar {
      position: relative;
      top: -2vh;
      font-size: 2rem;
      width: auto;
    }
  }
  .list-wrapper {
    #game-list li {
      font-size: 1.25rem;
    }
  }
}

.rates {
  margin-top: -15vh;
  height: 175vh;
  .title {
    font-size: 2rem;
  }
  .location-rate .btn {
    font-size: 1.05rem;
  }
  .prices {
    flex-wrap: wrap;
    .btn {
      margin: 1vh;
      font-size: 1.05rem;
    }
    button.btn.twenty-four {
      position: relative;
      left: 2vw;
    }
  }
  .price-info .price-wrapper {
    flex-direction: column;
    .card {
      width: 55%;
      padding: 4%;
      order: 2;
      p {
        font-size: 1.5rem;
      }
    }
    .descriptor {
      order: 1;
      margin-bottom: 4%;
    }
  }
}

.contact {
  height: 150vh;
  .contact-info {
    top: -8vh;
    max-width: 100%;
    h2 {
      font-size: 1.5rem;
    }
    p {
      padding: 0 1vh;
      font-size: 1.05rem;
    }
    .numbers h3, .numbers li {
      font-size: 1.05rem;
    }
  }
}

.contact .form-wrapper #contact-form {
  top: 0vh;
  height: 35vh;
  .input-field {
    input {
      padding: 1vh;
      margin: 2vh 5vw;
      width: 70%;
    }
    #comments {
      top: -25vh;
      height: 15.15vh;
    }
  }
  #submit {
    font-size: 1.05rem;
    top: 5vh;
    width: 50%;
    padding: 1vh;
  }
}

.location-wrapper {
  flex-direction: column;
  .location-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: 35%;
    width: 100%;
    .locations {
      height: auto;
      width: 50%;
      position: relative;
      top: -6vh;
      align-items: flex-end;
      .btn {
        width: 80%;
        font-size: 1.05rem;
      }
    }
    .address {
      position: relative;
      height: auto;
      width: 50%;
      top: -4vh;
      .hours, li {
        font-size: 1rem;
      }
    }
    .socialBar {
      position: relative;
      top: -6vh;
      left: -18vw;
      ul a {
        font-size: 1.5rem;
        margin: 1vh;
      }
    }
  }
  .location-map {
    height: 65%;
    width: 100%;
  }
}
}
